html {
    scroll-behavior: smooth;
}

.super {
    font-family: "Supermercado One", cursive;
    color: #5755d9;
}

.super-default {
    font-family: "Supermercado One", cursive;
}

.accordion-body {
    margin: 0px 0px 0px 32px;
}

.accordion-body p {
    margin: 0px 0px 10px 0px;
}

.accordion input:checked~.accordion-body,
.accordion[open] .accordion-body {
    max-height: 90rem;
}

.span-margin {
  margin-right: 4px;
}

.div-headboard {
  background-color: #fff;
  position:fixed;
  width: 100%;
  z-index: 999;
  border-bottom: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 1px 8px 0 rgba(0,0,0,.1);
}

.navbar-padding {
  padding: 20px 0 20px 0;
}

.logo {
  height: 76px;
  margin-right: 10px;
}

.section-header {
  padding-top: 80px;
  padding-bottom: 80px;
}

.small_svg_icon{
  transform: scale(0.75);
}

table { white-space: nowrap; }

.rowC{display:flex; flex-direction:row;}

.borderedCell {
  border-right: 1px solid #bababa;
  padding-right: 2.5% !important;
  margin-right: 2.5% !important;
}

.paddedCell {
  margin-left: 10%;
}
